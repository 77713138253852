import React from 'react';
// import { graphql } from 'gatsby';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Privacy = () => (
  <Layout bodyClass="page-privacy">
    <SEO title="Team" />
    <div className="intro intro-small">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <p>
        This privacy policy governs your use of the application Star 2 Apples
        (&#39;Application&#39;) for mobile devices that was created by Ian
        Clawson. Application was built for commercial sale and is intended for
        use as is.
      </p>

      <p>
        This privacy policy was last updated on August 22, 2021. Our privacy
        policy may change from time to time for any reason. If we make any
        material changes to our policies, we will place a prominent notice on
        our website or application. If you have any questions or concerns about
        our privacy policies, feel free to contact us at any time.
      </p>

      <h1>Summary</h1>
      <p>
        <b>
          <i>We do not collect your data at all, in any way, period.</i>
        </b>
      </p>
      <p>
        The service as it currently exists does not contain a backend server or
        database, and does not track any information about the user(s), locally
        on the device, on a remote server, or otherwise. As such, the
        Application does not track any user data, and even if it did, it has
        nowhere to send any such information.
      </p>

      <h1>Full</h1>

      {/* <p>
        This page is used to inform visitors regarding my policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use my Service.
      </p> */}

      {/* <p>
        If you choose to use my Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that I collect is used for providing and improving the Service. I will
        not use or share your information with anyone except as described in
        this Privacy Policy.
      </p>

      <p>
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which is accessible at Stars 2 Apples unless
        otherwise defined in this Privacy Policy.
      </p> */}

      <p>
        <b>
          <i>What Third Parties do we use?</i>
        </b>
      </p>
      <ul>
        <li>
          <a href="https://realm.io">Realm (local database)</a>
        </li>
        <li>
          <a href="https://github.com/onevcat/Kingfisher">
            Kingfisher (image downloading/caching)
          </a>
        </li>
        <li>
          <a href="https://github.com/DragonCherry/AssetsPickerViewController">
            AssetsPickerViewController (local image selection)
          </a>
        </li>
      </ul>

      <p>
        <b>
          <i>
            What information does the Application collect and how is it used?
          </i>
        </b>
      </p>

      <p>
        The Application does not collect or transmit any personally identifiable
        information (&#39;User Data&#39;) about you, such as your name, address,
        phone number or email address.
      </p>
      <p>
        The Application allows users to create a schedule that displays the time
        of a lesson, the name of an individual receiving the lesson, and the
        teaching assets used at time of the lesson. All information of this
        nature (&#39;Teaching Data&#39;) is not personally identifiable and is
        only stored on the device. All Teaching Data is manually input by the
        user and is not collected nor generated in any capacity, and users are
        at all times in full and complete control of the Teaching Data they
        input. The Application has no automatic means and does not provide any
        manual means of sharing this information outside of the device.
      </p>

      <p>
        <b>
          <i>Do you share personally identifiable information information?</i>
        </b>
      </p>
      <p>
        As the Application itself does not collect, transmit, or maintain any
        personally identifiable information, there is no such means for sharing
        any such data.
      </p>

      <p>
        <b>
          <i>Do you use vendors or analytics providers?</i>
        </b>
      </p>
      <p>
        No. The Application does not collect, transmit, or maintain user data.
      </p>

      <p>
        <b>
          <i>
            Do you comply with the Children’s Online Privacy Protection Act
            (COPPA)?
          </i>
        </b>
      </p>
      <p>
        Yes. We do not solicit nor gather any data from children under the age
        of 13. If a parent or guardian becomes aware that his or her child has
        provided us with information without their consent, he or she should
        contact us.
      </p>

      <h1>Contact Us</h1>
      <p>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us.
      </p>
      <br />
    </div>
  </Layout>
);

export default Privacy;
